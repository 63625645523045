<template>
  <div class="group-detail-container" :class="{
    forbidden,
    loading: requesting,
    'hint-del': hintDel,
  }">
    <!-- 小组基本信息 -->
    <div class="group__base-wrapper">
      <div class="group__base-info" :class="{
        'skeleton-loading-wrapper': skeletonScreen,
      }">
        <div class="base-info__header" :class="{
          'skeleton-item': skeletonScreen,
        }" :data-src="groupCover" :data-list="groupCover ? [groupCover] : []" v-preview>
          <img v-if="info" :src="info.cover ? info.cover : defaultBg | parseImage" alt="" />
        </div>
        <div class="base-info__text">
          <div class="info_block" :class="{
            'skeleton-item': skeletonScreen,
          }">
            <template v-if="info">
              <div class="info_line h-between">
                <p class="title">
                  {{ info.title }}
                </p>
                <router-link v-slot="{ navigate }" :to="{
                  path: `/group/edit/${this.groupID}`,
                }">
                  <p v-if="info && !forbidden" class="icon" @click="navigate">
                    <svg-icon icon-class="icon-pen"></svg-icon>
                  </p>
                </router-link>
              </div>
              <div class="info_line h-between">
                <p>
                  <template v-if="Date.now() / 1000 - info.created_at > 60">建立 </template>
                  <strong>{{ info.created_at | timeAgoNumber }}</strong>
                  {{ info.created_at | timeAgoText }}
                </p>
                <router-link v-slot="{ navigate }" :to="{
                  path: `/group/${groupID}/members`,
                }">
                  <p v-if="forbidden">
                    成员<strong> {{ info.members.length }} </strong>人
                  </p>
                  <p v-else @click="navigate">
                    成员<strong> {{ info.members.length }} </strong>人&nbsp;&gt;
                  </p>
                </router-link>
              </div>
            </template>
          </div>
          <div class="info_summary" :class="{
            'skeleton-item -height-medium': skeletonScreen,
            strsub: strsubShow,
          }" ref="txCompare" @click="showAll">
            <textarea class="" readonly tabindex="-1" rows="4"></textarea>
            <p class="ggg" v-if="info">
              <strong>小组简介：</strong><span v-html="fomatSummary"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="base-timeline-info">
        <row-info-bar title="小组时光" :val="totalTimelineCount"
          :link="forbidden ? '' : `/group/${groupID}/timelines`"></row-info-bar>
        <row-info-bar class="red" v-if="!forbidden && applyCount > 0" title="加入请求" :val="applyCount"
          :link="`/group/${groupID}/apply`"></row-info-bar>
      </div>
    </div>
    <router-view :currentUnlockLists="currentUnlockList" :groupRole="groupRole" :class="{
      forbidden,
      unactived: forbidden && user && !(applyRecord || user.actived),
    }"></router-view>
    <template v-if="forbidden">
      <template v-if="user">
        <!-- 激活的人 -->
        <div v-if="applyRecord || user.actived" class="apply-container">
          <div class="status-bar">
            <van-button @click="onGroupAccept" v-if="applyRecord && Number(applyRecord.invite_uid) > 0"
              :loading="applying" class="apply">立即加入</van-button>
            <van-button v-else @click="apply" v-countdown="applyRecord ? applyRecord.expired_at : 0"
              :class="[applyRecord === null ? 'apply' : 'waiting']" :loading="applying">
              {{ applyRecord ? "" : "申请加入" }}
            </van-button>
          </div>
        </div>
        <!-- 没激活的人 -->
        <div class="scan-container" v-else>
          <template v-if="!user.actived">
            <qrcode class="qr__box" :value="qr.url" ref="qrcode" style="display: none"></qrcode>
            <div class="qr-container" v-if="qr && qrSrc">
              <div class="qr-box">
                <img :src="qrSrc" alt="" />
              </div>
              <p>扫码发起申请</p>
            </div>
            <div class="bottom-text">
              <p><strong>「时光」和朋友在一起</strong></p>
            </div>
          </template>
        </div>
        <div @click="goBackext" class="back-bt" v-if="'wechatpush' !== referer">返回首页</div>
      </template>
    </template>
    <template v-else-if="!requesting">
      <!-- 主要内容 -->
      <!-- 标语 -->
      <slogan text="✖ 同伴的标志 ✖"></slogan>
      <!-- 添加时光 -->
      <div class="topic-create-bt group" @click="onCreateTimeline">
        <svg-icon icon-class="icon-plus-red" color="#027711"></svg-icon>
      </div>
      <!-- 底部固定 -->
      <div class="group__footer-fixed-nav">
        <div class="nav__item back" @click="goBackext">
          <strong>返回</strong>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import * as dict from '@/utils/dict';
import qrcode from 'qrcode.vue';
import wxtool from '@/utils/wx-tool';
import rowInfoBar from '../common/_row-info-bar.vue';
import slogan from '../common/_slogan.vue';

export default {
  name: 'group-detail',
  props: ['groupID'],
  components: {
    slogan,
    rowInfoBar,
    qrcode,
  },
  data() {
    return {
      info: null,
      skeletonScreen: true,
      forbidden: false,
      currentUnlockList: null,
      // 跳转到时光
      timelineLink: '/',
      totalTimelineCount: 0,
      requesting: false,
      applying: false,
      applyRecord: null,
      applyCount: 0,
      isAdmin: false,
      hasStrsub: false,
      strsubShow: false,
      qr: null,
      qrSrc: null,
      hintDel: false,
      hintMsg: '该小组或已解散，请联系小组成员。',
      qrRender: false,
    };
  },
  computed: {
    ...mapState(['userGroupBaseInfo', 'user', 'forward']),
    groupCover() {
      const { info } = this;
      if (info) {
        return info.cover ? this.$root.$options.filters.parseImage(info.cover) : this.defaultBg;
      }
      return '';
    },
    fomatSummary() {
      if (this.info && this.info.summary) {
        const reg = /https?:\/{2}[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
        const result = this.info.summary.replaceAll(reg, (val) => `<a href='${val}'>${val}</a>`);
        return result;
      }
      return '';
    },
    referer() {
      return this.$route.query.referer || undefined;
    },
    isFromOutWechatShare() {
      return this.$route.query.from === 'wechat';
    },
    requestedAll() {
      return this.user !== null && this.info !== null;
    },
    lastPage() {
      return this.forward ? this.forward : '/groups';
    },
    admins() {
      if (this.info) {
        const admins = this.info.admins.length > 0 ? this.info.admins.map((item) => Number(item.uid)) : [];
        admins.push(this.info.author ? Number(this.info.author.uid) : 0);
        return admins;
      }
      return [];
    },
    members() {
      return this.info ? this.info.members.map((item) => Number(item.uid)) : [];
    },
    groupRole() {
      if (this.user) {
        if (this.members.indexOf(this.user.uid) === -1) {
          return 'strange';
        }
        const isAdmin = this.admins.indexOf(this.user.uid);
        return isAdmin >= 0 ? 'user' : 'friend';
      }
      return 'strange';
    },
    // qrSrc() {
    // this.$nextTick(() => {
    //   if (this.qr) {
    //     const canvas = this.$refs.qrcode.$el.querySelector('canvas');
    //     return canvas.toDataURL('image/png');
    //   }
    //   return null;
    // });
    // return null;
    // },
  },
  watch: {
    requestedAll(val) {
      if (val) {
        wxtool.share(
          {
            title: `${this.user.nickname} 和你分享了小组「${this.info.title}」`,
            desc: `小组简介：${this.info.summary}`,
            link: `${process.env.VUE_APP_BASE}group/${this.info.id}`,
            imgUrl: `${this.info.cover
              ? this.$root.$options.filters.parseImage(this.info.cover)
              : this.user.headimgurl
            }`,
          },
          this.$route.fullPath,
        );
      }
    },
    hintDel(val) {
      const that = this;
      if (val) {
        that.$dialog.confirm({
          title: that.hintMsg || '您已经不是该小组成员。',
          showCancelButton: true,
          confirmButtonText: '删除小组',
          cancelButtonText: that.isFromOutWechatShare ? '返回首页' : '确认',
          beforeClose: (action, done) => {
            if (action === 'confirm') {
              that.userDelGroup().then((res) => {
                console.log(res);
                done();
                that.goBack();
              });
            } else if (action === 'cancel') {
              done();
              that.$router.replace({
                path: '/',
              });
            } else {
              done();
              that.goBack();
            }
          },
        });
      }
    },
  },
  mounted() {
    this.getInfo();
    this.$root.$on('forbidden', () => {
      this.forbidden = true;
    });
  },
  updated() {
    const that = this;
    that.$nextTick(() => {
      if (that.$refs.qrcode && !that.qrRender) {
        that.qrRender = true;
        const canvas = that.$refs.qrcode.$el.querySelector('canvas');
        that.qrSrc = canvas.toDataURL('image/png');
      }
    });
  },
  methods: {
    ...mapMutations(['SET_GROUP_COUNTS', 'UPDATE_MYGROUPS_INFO']),
    getInfo() {
      const that = this;
      that.requesting = true;
      that.$request
        .get(`api/group/${that.groupID}`)
        .then((res) => {
          const { data } = res;
          that.info = data.info;
          that.isAdmin = data.isAdmin;
          that.forbidden = !data.isMember;
          that.applyRecord = data.applyRecord;
          that.applyCount = data.applyCount;
          that.qr = data.qr;
          that.hintDel = data.hintDel;
          that.SET_GROUP_COUNTS({
            groupid: that.groupID,
            currentCount: data.clearLists.length,
            totalTimelineCount: data.totalTimelineCount,
            totalCount: 0,
          });
          that.skeletonScreen = false;
          that.currentUnlockList = data.clearLists;
          that.totalTimelineCount = data.totalTimelineCount;

          const storeData = {
            totalTimelineCount: data.totalTimelineCount,
            ...data.info,
          };
          that.UPDATE_MYGROUPS_INFO(storeData);
          that.$nextTick(() => {
            const { height: totalHeight } = that.$refs.txCompare.getBoundingClientRect();
            const { height: txHeight } = that.$refs.txCompare
              .querySelector('textarea')
              .getBoundingClientRect();
            if (totalHeight > txHeight) {
              that.strsubShow = true;
              that.hasStrsub = true;
            }
          });
        })
        .catch((err) => {
          that.$notify({
            type: 'danger',
            message: err.message,
          });
          that.forbidden = true;
          if (err?.status === 404) {
            that.hintDel = true;
            // that.hintMsg = '该小组或已解散，请联系小组成员。';
          }
        })
        .finally(() => {
          that.requesting = false;
        });
    },
    onCreateTimeline() {
      this.$router.push({
        path: `/timeline/edit?groupID=${this.groupID}`,
      });
    },
    apply() {
      const that = this;
      if (!that.applying) {
        that.applying = true;
        that.$request
          .get(`api/group/apply/${this.groupID}`)
          .then((res) => {
            const { data } = res;
            that.applyRecord = data;
          })
          .finally(() => {
            that.applying = false;
          });
      }
    },
    showAll() {
      if (this.hasStrsub) {
        this.strsubShow = !this.strsubShow;
      }
    },
    userDelGroup() {
      return this.$request.get(`api/user/group/delete/${this.groupID}`);
    },
    onGroupAccept() {
      const that = this;
      const { id: groupInvitedID } = this.applyRecord;
      if (!that.applying) {
        that.applying = true;
        that.$request
          .get(`api/invite/accept/${groupInvitedID}?type=${dict.NOTICE_TYPE_GROUP_INVITE}`)
          .then(() => {
            window.location.reload();
          })
          .finally(() => {
            that.applying = false;
          });
      }
    },
    goBackext() {
      this.$router.replace({
        path: '/groups',
      });
    },
  },
};
</script>

<style></style>
