var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group-detail-container",class:{
  forbidden: _vm.forbidden,
  loading: _vm.requesting,
  'hint-del': _vm.hintDel,
}},[_c('div',{staticClass:"group__base-wrapper"},[_c('div',{staticClass:"group__base-info",class:{
      'skeleton-loading-wrapper': _vm.skeletonScreen,
    }},[_c('div',{directives:[{name:"preview",rawName:"v-preview"}],staticClass:"base-info__header",class:{
        'skeleton-item': _vm.skeletonScreen,
      },attrs:{"data-src":_vm.groupCover,"data-list":_vm.groupCover ? [_vm.groupCover] : []}},[(_vm.info)?_c('img',{attrs:{"src":_vm._f("parseImage")(_vm.info.cover ? _vm.info.cover : _vm.defaultBg),"alt":""}}):_vm._e()]),_c('div',{staticClass:"base-info__text"},[_c('div',{staticClass:"info_block",class:{
          'skeleton-item': _vm.skeletonScreen,
        }},[(_vm.info)?[_c('div',{staticClass:"info_line h-between"},[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.info.title)+" ")]),_c('router-link',{attrs:{"to":{
                path: `/group/edit/${this.groupID}`,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [(_vm.info && !_vm.forbidden)?_c('p',{staticClass:"icon",on:{"click":navigate}},[_c('svg-icon',{attrs:{"icon-class":"icon-pen"}})],1):_vm._e()]}}],null,false,2301264653)})],1),_c('div',{staticClass:"info_line h-between"},[_c('p',[(Date.now() / 1000 - _vm.info.created_at > 60)?[_vm._v("建立 ")]:_vm._e(),_c('strong',[_vm._v(_vm._s(_vm._f("timeAgoNumber")(_vm.info.created_at)))]),_vm._v(" "+_vm._s(_vm._f("timeAgoText")(_vm.info.created_at))+" ")],2),_c('router-link',{attrs:{"to":{
                path: `/group/${_vm.groupID}/members`,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [(_vm.forbidden)?_c('p',[_vm._v(" 成员"),_c('strong',[_vm._v(" "+_vm._s(_vm.info.members.length)+" ")]),_vm._v("人 ")]):_c('p',{on:{"click":navigate}},[_vm._v(" 成员"),_c('strong',[_vm._v(" "+_vm._s(_vm.info.members.length)+" ")]),_vm._v("人 > ")])]}}],null,false,3590255766)})],1)]:_vm._e()],2),_c('div',{ref:"txCompare",staticClass:"info_summary",class:{
          'skeleton-item -height-medium': _vm.skeletonScreen,
          strsub: _vm.strsubShow,
        },on:{"click":_vm.showAll}},[_c('textarea',{attrs:{"readonly":"","tabindex":"-1","rows":"4"}}),(_vm.info)?_c('p',{staticClass:"ggg"},[_c('strong',[_vm._v("小组简介：")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.fomatSummary)}})]):_vm._e()])])]),_c('div',{staticClass:"base-timeline-info"},[_c('row-info-bar',{attrs:{"title":"小组时光","val":_vm.totalTimelineCount,"link":_vm.forbidden ? '' : `/group/${_vm.groupID}/timelines`}}),(!_vm.forbidden && _vm.applyCount > 0)?_c('row-info-bar',{staticClass:"red",attrs:{"title":"加入请求","val":_vm.applyCount,"link":`/group/${_vm.groupID}/apply`}}):_vm._e()],1)]),_c('router-view',{class:{
    forbidden: _vm.forbidden,
    unactived: _vm.forbidden && _vm.user && !(_vm.applyRecord || _vm.user.actived),
  },attrs:{"currentUnlockLists":_vm.currentUnlockList,"groupRole":_vm.groupRole}}),(_vm.forbidden)?[(_vm.user)?[(_vm.applyRecord || _vm.user.actived)?_c('div',{staticClass:"apply-container"},[_c('div',{staticClass:"status-bar"},[(_vm.applyRecord && Number(_vm.applyRecord.invite_uid) > 0)?_c('van-button',{staticClass:"apply",attrs:{"loading":_vm.applying},on:{"click":_vm.onGroupAccept}},[_vm._v("立即加入")]):_c('van-button',{directives:[{name:"countdown",rawName:"v-countdown",value:(_vm.applyRecord ? _vm.applyRecord.expired_at : 0),expression:"applyRecord ? applyRecord.expired_at : 0"}],class:[_vm.applyRecord === null ? 'apply' : 'waiting'],attrs:{"loading":_vm.applying},on:{"click":_vm.apply}},[_vm._v(" "+_vm._s(_vm.applyRecord ? "" : "申请加入")+" ")])],1)]):_c('div',{staticClass:"scan-container"},[(!_vm.user.actived)?[_c('qrcode',{ref:"qrcode",staticClass:"qr__box",staticStyle:{"display":"none"},attrs:{"value":_vm.qr.url}}),(_vm.qr && _vm.qrSrc)?_c('div',{staticClass:"qr-container"},[_c('div',{staticClass:"qr-box"},[_c('img',{attrs:{"src":_vm.qrSrc,"alt":""}})]),_c('p',[_vm._v("扫码发起申请")])]):_vm._e(),_vm._m(0)]:_vm._e()],2),('wechatpush' !== _vm.referer)?_c('div',{staticClass:"back-bt",on:{"click":_vm.goBackext}},[_vm._v("返回首页")]):_vm._e()]:_vm._e()]:(!_vm.requesting)?[_c('slogan',{attrs:{"text":"✖ 同伴的标志 ✖"}}),_c('div',{staticClass:"topic-create-bt group",on:{"click":_vm.onCreateTimeline}},[_c('svg-icon',{attrs:{"icon-class":"icon-plus-red","color":"#027711"}})],1),_c('div',{staticClass:"group__footer-fixed-nav"},[_c('div',{staticClass:"nav__item back",on:{"click":_vm.goBackext}},[_c('strong',[_vm._v("返回")])])])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-text"},[_c('p',[_c('strong',[_vm._v("「时光」和朋友在一起")])])])
}]

export { render, staticRenderFns }